.checkbox{
  margin-top: 0;
}

.this{
  font-size: 0.9em;
  font-weight: bold;
  color: #333;

  span{
    color: $blue-color;
  }
}

.choice-block{
  float: left;
  width: 40%;
  margin-right: 10%;
  margin-bottom: 3em;
}

.choice{
  position: relative;

  .image{
    img{
      max-width: 100%;
      width: 100%;

    }
  }
}

.check{
  bottom: 0;
  right: 0;
  position: absolute;
  padding-left: 0;
}
.checkline{
  width: 90%;
  border-top: 12px solid $blue-color;
  height: 1px;
  margin-bottom: 15px;
}

.green, .webcam {
  .checkline {
    border-color: $green-color;
  }

  .this{
    span{
      color: $green-color;
    }
  }

}