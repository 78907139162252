.form-container{
  background-color: $blue-color;
  color: #ffffff;
  margin-bottom: 4em;
  margin-top: 4em;
  padding: 4em 0 !important;

  h1{
    font-size: 4em;
  }

  .line{
    margin-top: 2em;
    border-color: white !important;
    margin-bottom: 3em;
  }

  button{
    width: 100%;
    font-weight: bold;
    text-transform: uppercase;
    color: white;
    font-size: 2em;
    background-color: #a8a8a7;
  }

  .form-group{
    margin-bottom: 2.5em;
  }

    input{
      font-size: 2em;
      padding: 1em 0.5em;
    }


  .errors{
    font-size: 1.4em;
  }

  ul{
    margin-top: 10px;
    padding: 0 0 0 15px;
  }
}

.green, .webcam{
  .form-container {
    background-color: $green-color;
  }
}