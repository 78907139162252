.header-container{
  border-bottom: 6px solid $blue-color;
  margin-bottom: 4em;
}

.shadow{
  -webkit-box-shadow: inset 0px 20px 18px -20px rgba(214,214,214,0.63);
  -moz-box-shadow: inset 0px 20px 18px -20px rgba(214,214,214,0.63);
  box-shadow: inset 0px 20px 18px -20px rgba(214,214,214,0.63);
}

.header{
  min-height: 200px;
  height: 55em;
  background-size: cover;
  background-position: top center;
  padding: 3em 0 0 3em;

  h1{
    padding-left: 0.06em;
    font-size: 6em;
    color: #000000;
  }

  .line{
    margin-top: 2em;
  }
}

.blue{
  .header{
    background-image: url('../images/header-blue.jpg');
  }
}

.green, .webcam {
  .header{
    background-image: url('../images/header-green.jpg');
  }
  .header-container{
    border-color: $green-color;
  }
}

@media only screen and (max-width: 650px) {
  .header {
    h1 {
      font-size: 4em;
    }
  }
}

.page-confirm{
  .header{
    h1 {
      margin-top: 2em;
    }
  }

  .confirm {
    p {
      padding: 2.5em 0;
      font-size: 2em;
    }
  }

}