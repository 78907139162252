$blue-color: #092f87;
$green-color: #c3d600;

$border-radius-base: 0;

$input-border-radius: 0;
$input-border-radius-large: 0;
$input-border-radius-small: 0;

$btn-border-radius: 0;
$btn-border-radius-large: 0;
$btn-border-radius-small: 0;