@import "vars";
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";


@import "components/boxes";
@import "components/header";
@import "components/form";
@import "components/webcam";

body{
  font-size: 100%;
}

h1{
  font-family: 'Open Sans', sans-serif;
  font-weight: normal;
  text-transform: uppercase;
}

.line{
  width: 4em;
  border-top: 12px solid $blue-color;
}

.green, .webcam{
  .line {
    border-color: $green-color;
  }
}

.logo{
  padding: 1.2em 0;

  img{
    max-width: 100%;
  }
}

.container-fluid{
  padding: 0;
}

