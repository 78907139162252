.cam-container{

  padding: 0 6em 3em 4em;

  h1{
    color: $green-color;
  }

  p{
    font-size: 1.6em;
  }

  .line{
    margin-top: 2.5em;
    margin-bottom: 4.5em;
  }

  input{
    background-color: #f1f1f1;
    border-color: #f1f1f1;
  }

  button{
    width: 100%;
    font-weight: bold;
    text-transform: uppercase;
    color: white;
    background-color: #a8a8a7;
  }

  .errors{
    font-size: 1.4em;
    color: black;
  }

  ul{
    margin-top: 10px;
    padding: 0 0 0 15px;
  }
}
